import React, { FC, useContext } from 'react';

import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import UmbracoImage from 'common/Image/UmbracoImage';
import { BannerSimpleProps } from './models';

import './BannerSimple.scss';

const Banner: FC<BannerSimpleProps> = ({
  bannerSimpleTitle,
  imageAsBackground,
  imageMobileAsBackground,
  imageAsBackgroundAlt,
  lazyload = true,
}) => {
  const screenRecognitionContext = useContext(ScreenRecognitionContext);

  return (
    <div className="banner-simple banner-simple--with-image-as-background">
      {bannerSimpleTitle ? (
        <div
          className="banner-simple--with-image-as-background__content"
          data-testid="banner-simple-content"
        >
          <h2 className="banner-simple__title">{bannerSimpleTitle}</h2>
        </div>
      ) : null}

      {screenRecognitionContext.isMobile && imageMobileAsBackground ? (
        <UmbracoImage
          {...{ lazyload }}
          image={imageMobileAsBackground}
          alt={imageAsBackgroundAlt}
          className="banner-simple--with-image-as-background__image"
        />
      ) : (
        <UmbracoImage
          {...{ lazyload }}
          image={imageAsBackground}
          alt={imageAsBackgroundAlt}
          className="banner-simple--with-image-as-background__image"
        />
      )}
    </div>
  );
};

export default Banner;
