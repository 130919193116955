import React, { FC } from 'react';

import Slider from 'react-slick';
import { useScreenRecognition } from 'utils/hooks';
import { SuggestionsType } from './models';
import SuggestionsItem from './SuggestionsItem';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Suggestions.scss';

const Suggestions: FC<SuggestionsType> = ({
  suggestionsItemNodes: { suggestionsListTitle, suggestionsList },
}) => {
  const { isXl } = useScreenRecognition();

  return (
    <div className="suggestions">
      <div className="suggestions__container">
        <h2 className="suggestions__title">{suggestionsListTitle}</h2>
      </div>

      <div className="suggestions__wrapper">
        <div className="suggestions__container">
          {suggestionsList && suggestionsList.length ? (
            <div className="suggestions__items" data-testid="suggestions-items">
              <Slider
                slidesToShow={isXl ? 3 : 1}
                infinite={false}
                variableWidth
                className="landing-page__reviews"
              >
                {suggestionsList.map((suggestionsListItem) => (
                  <SuggestionsItem
                    key={suggestionsListItem.suggestionTitle}
                    suggestionTitle={suggestionsListItem.suggestionTitle}
                    suggestionImage={suggestionsListItem.suggestionImage}
                    suggestionDescription={suggestionsListItem.suggestionDescription}
                  />
                ))}
              </Slider>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
