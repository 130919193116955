import React, { FC } from 'react';
import UmbracoImage from 'common/Image/UmbracoImage';
import { SuggestionsItemType } from '../models';

import './SuggestionsItem.scss';

const SuggestionsItem: FC<SuggestionsItemType> = ({
  suggestionImage,
  suggestionTitle,
  suggestionDescription,
}) => (
  <div className="suggestions-item">
    {suggestionImage ? (
      <div className="suggestions-item__image">
        <UmbracoImage image={suggestionImage} alt={suggestionTitle} />
      </div>
    ) : null}
    <div className="suggestions-item__content">
      <h2 className="suggestions-item__title">{suggestionTitle}</h2>
      <p className="suggestions-item__description">{suggestionDescription}</p>
    </div>
  </div>
);

export default SuggestionsItem;
