import React, { FC } from 'react';
import { AboutUsHeaderType } from './models';

import './AboutUsHeader.scss';

const AboutUsHeader: FC<AboutUsHeaderType> = ({
  aboutUsHeaderNodes: { aboutUsTitle, aboutUsDescription, aboutUsSignature },
}) => (
  <div className="about-us">
    <div className="about-us__container">
      <h2 className="about-us__title">{aboutUsTitle}</h2>
      <p className="about-us__description">{aboutUsDescription}</p>
      <p className="about-us__signature">{aboutUsSignature}</p>
    </div>
  </div>
);

export default AboutUsHeader;
